<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="实时情报" contentHeight="256">
      <div class="news">
        <div class="news-head">
          <div class="news-head-con">
            <div class="news-name">走访日期</div>
            <div class="news-con">走访情况</div>
          </div>
        </div>
        <div class="news-content">
          <webkitScroll>
            <scrollList :listData="newsData">
              <template v-slot="{ row }">
                <div class="news-item">
                  <div class="news-name">{{ row.vistDate }}</div>
                  <div class="news-con">{{ row.dic_orgId }}走访了{{ row.cpyName }}</div>
                </div>
              </template>
            </scrollList>
          </webkitScroll>
        </div>
      </div>
    </boxItem>
    <boxItem style="margin-bottom: 12px" title="累计指标完成情况" contentHeight="200">
      <div style="overflow: hidden;height: 100%">
        <div style="float: left; width: 50%; height: 100%">
          <div style="height: 100%; position: relative">
            <VEchart :option="targetOption" />
            <div class="chartAppeal-con">
              <div class="chartAppeal-num" style="color: #FFFFFF">
                {{
                  parseInt(
                    (targetData[1].value / (targetData[0].value + targetData[1].value)) * 100
                  ) || 0
                }}%
              </div>
              <div class="chartAppeal-text" style="color: #B7BABE">指标完成率</div>
            </div>
          </div>
        </div>
        <div style="float: left; width: 50%; height: 100%;padding-top: 66px;box-sizing: border-box">
          <div class="len-item">
            <div class="len-item-icon" style="background-color: #75FFFF"></div>
            <div class="len-item-name">完成数</div>
            <div class="len-item-text">{{ targetData[1].value }}</div>
          </div>
          <div class="len-item">
            <div class="len-item-icon" style="background-color: #373942"></div>
            <div class="len-item-name">指标数</div>
            <div class="len-item-text">{{ targetData[0].value + targetData[1].value }}</div>
          </div>
        </div>
      </div>
    </boxItem>
    <boxItem title="本月指标完成情况" contentHeight="312">
      <webkitScroll>
        <div style="height: 800px">
          <VEchart :option="companyOption" />
        </div>
      </webkitScroll>
    </boxItem>
  </div>
</template>

<script>
import scrollList from './scrollList'
import boxItem from './boxItem'
import webkitScroll from './webkitScroll'
import { aggregateList } from '@/api/aggregate2'
import { ref, onMounted } from 'vue'
import { CURRENT_MONTH, getEndDayOfMonth, getFirstDayOfMonth } from '@/utils/date'
export default {
  name: 'right',
  components: {
    boxItem,
    webkitScroll,
    scrollList
  },
  setup: () => {
    // 实时情报
    const newsData = ref([])
    // 满意度指标
    const targetData = ref([
      { value: 0, name: '未完成' },
      { value: 0, name: '完成数' }
    ])
    const targetOption = ref({})
    function target(data) {
      targetOption.value = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#373942', '#75FFFF'],
        legend: {
          show: false
        },
        series: [
          {
            name: '指标情况',
            type: 'pie',
            radius: ['55%', '70%'],
            label: {
              show: false
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }

    // 本月部门概况
    const companyOption = ref({})
    function company(data) {
      companyOption.value = {
        legend: {
          show: true,
          top: 10,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: 120,
          top: 40,
          bottom: 30
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'category',
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#B7BABE'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#B7BABE'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)'],
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '指标数',
            data: data.seriesData.target,
            type: 'bar',
            barMaxWidth: 8,

            label: {
              show: false
            },
            itemStyle: {
              color: '#75FFFF'
            }
          },
          {
            name: '完成数',
            data: data.seriesData.visit,
            type: 'bar',
            barMaxWidth: 8,
            label: {
              show: false
            },
            barGap: '-100%',
            itemStyle: {
              color: '#0C5ED8'
            }
          }
        ]
      }
    }

    const fetchData = async () => {
      const startDate = getFirstDayOfMonth(CURRENT_MONTH)
      const endDate = getEndDayOfMonth(CURRENT_MONTH)
      const params = {
        mouthDeptIndex: {
          cmdList: [
            {
              $match: {
                $or: [
                  { 'data.indexDate': { $gte: startDate, $lte: endDate } },
                  { 'data.indexDate': startDate.substring(0, 7) }
                ]
              }
            },
            {
              $group: {
                _id: '$data.assignedDepId',
                indexCount: {
                  $sum: 1
                },
                visitCount: {
                  $sum: '$data.isVisit'
                }
              }
            },
            {
              $sort: {
                indexCount: 1
              }
            }
          ],
          dicCodes: [
            {
              name: 'id',
              type: 4
            }
          ],
          templateName: 'b_console_visit_index'
        },
        deptIndex: {
          cmdList: [
            {
              $group: {
                _id: '指标情况',
                indexCount: {
                  $sum: 1
                },
                visitCount: {
                  $sum: '$data.isVisit'
                }
              }
            },
            {
              $sort: {
                indexCount: -1
              }
            }
          ],
          templateName: 'b_console_visit_index'
        },
        'now-info': {
          cmdList: [
            { $sort: { 'data.visitTime': -1 } },
            { $limit: 10 },
            {
              $project: {
                _id: 0,
                orgId: 1,
                cpyName: '$data.enterpriseName',
                vistDate: { $substr: ['$data.visitTime', 0, 10] }
              }
            }
          ],
          dicCodes: [{ name: 'orgId', type: 4 }],
          templateName: 'b_console_visit'
        }
      }
      const res = await aggregateList(params)
      // 实时情报
      newsData.value = res.result['now-info']

      // console.log(res)
      // 累计指标完成情况

      const data = res.result.deptIndex[0]
      targetData.value[0].value = data.indexCount - data.visitCount
      targetData.value[1].value = data.visitCount
      // console.log(targetData.value)
      target(targetData.value)

      // 本月部门概况
      const companyData = {
        xData: [],
        seriesData: {
          visit: [],
          target: []
        }
      }
      console.log(
        '%c [ res.result["deptIndex"] ]-304',
        'font-size:13px; background:pink; color:#bf2c9f;',
        res.result.mouthDeptIndex
      )
      res.result.mouthDeptIndex.forEach(item => {
        companyData.xData.push(item.dic_id)
        companyData.seriesData.visit.push(item.visitCount)
        companyData.seriesData.target.push(item.indexCount)
      })
      company(companyData)
    }
    onMounted(() => {
      fetchData()
      setInterval(() => {
        fetchData()
      }, 10 * 60 * 1000)
    })
    return { newsData, targetOption, targetData, companyOption }
  }
}
</script>

<style scoped>
.news-content {
  width: 100%;
  height: calc(100% - 50px);
}
.news {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.news-head {
  margin-bottom: 10px;
  padding: 3px 0;
  border-bottom: 1px solid rgb(12, 94, 216);
  border-top: 1px solid rgb(12, 94, 216);
}
.news-head-con {
  overflow: hidden;
  padding: 6px 6px 6px 24px;
  background-color: #0c5ed8;
}

.news-item {
  overflow: hidden;
  padding: 6px 6px 6px 24px;
  margin-bottom: 6px;
  background-color: #373942;
}
.news-name {
  float: left;
  width: 100px;
}
.news-con {
  float: left;
  width: 300px;
}
.chartAppeal-con {
  position: absolute;
  left: 50%;
  top: 0;
  pointer-events: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}
.chartAppeal-num {
  font-size: 40px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #0052d9;
  line-height: 47px;
}
.chartAppeal-text {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 106, 244, 0.5);
  line-height: 14px;
}
.len-item {
  overflow: hidden;
  padding-bottom: 5px;
  padding-left: 8px;
  margin-bottom: 8px;
  margin-right: 40px;
  border-bottom: 1px solid #646779;
}
.len-item-icon {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  margin-top: 5px;
  margin-right: 7px;
}
.len-item-name {
  float: left;
  color: rgb(183, 186, 190);
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.len-item-text {
  float: left;
  width: 120px;
  color: rgb(255, 255, 255);
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
</style>
