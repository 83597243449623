// 聚合查询接口
import http from '@/utils/http'
import { getBaseUrl } from '@/config/apiConfig'
import dbMap from './dbMap'
const BASE_URL2 = getBaseUrl().BASE_URL2

const api = {
  aggregate: `${BASE_URL2}/commonInstance/aggregate`,
  aggregateList: `${BASE_URL2}/commonInstance/aggregateList`
}

/**
 * 按街道统计楼宇数量
 * @param {} params
 * @returns
 */
export const getBuildingCount = (params = {}) => {
  const { streetId } = params

  const cmdList = []
  if (streetId) {
    cmdList.push({ $match: { 'data.street': streetId } })
  }
  cmdList.push({ $count: 'count' })

  const templateName = dbMap.B_CONSOLE_BUILDING
  return http({
    url: api.aggregate,
    method: 'post',
    data: {
      cmdList,
      templateName
    }
  })
}

/**
 * 按街道统计楼宇的面积
 * @param {} params
 */
export const getBuildingArea = (params = {}) => {
  const { streetId, key = 'totalArea' } = params
  const cmdList = []
  if (streetId) {
    cmdList.push({ $match: { 'data.street': streetId } })
  }
  cmdList.push({ $group: { _id: '楼宇面积', sum: { $sum: `$data.${key}` } } })

  const templateName = dbMap.B_CONSOLE_BUILDING

  return http({
    url: api.aggregate,
    method: 'post',
    data: {
      cmdList,
      templateName
    }
  })
}

export const getCompanyCount = (params = {}) => {
  const { industyTyp } = params
  const cmdList = []
  if (industyTyp) {
    cmdList.push({ $match: { 'data.industyTyp': industyTyp } })
  }
  cmdList.push({ $count: 'count' })

  const templateName = dbMap.B_CONSOLE_COMPANY
  return http({
    url: api.aggregate,
    method: 'post',
    data: {
      cmdList,
      templateName
    }
  })
}

export const aggregateList = params => {
  if (!params) return

  return http({
    url: api.aggregateList,
    method: 'post',
    data: params
  })
}

export const aggregate = params => {
  if (!params) return

  return http({
    url: api.aggregate,
    method: 'post',
    data: params
  })
}
