<template>
  <span class="number-box">
<!--    {{numFormat(number)}}-->
    <CountTo class="count" :startVal="0" :endVal="number" :duration="1000" />
  </span>
</template>

<script>
import { CountTo } from 'vue-count-to2'
export default {
  name: 'Number',
  components: {
    CountTo
  },
  props: {
    number: Number
  },
  setup: () => {
    // function numFormat (num) {
    //   const res = num.toString().replace(/\d+/, function (n) { // 先提取整数部分
    //     return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
    //       return $1 + ','
    //     })
    //   })
    //   return res
    // }
    // return { numFormat }
  }
}
</script>

<style scoped>
  @import '../../../assets/styles/font.css';
  .number-box{
    color: inherit;
    line-height: inherit;
    font-size: inherit;
    font-family: 'number';
    letter-spacing: inherit;
    font-weight: 400;
  }
</style>
