<template>
  <div>
    <div class="title-center"><div class="title-center-con">诉求累计总量</div></div>
    <NumberPanel :number="counts || 0"></NumberPanel>
    <div class="info-box">
      <div class="info-item type1">
        <div class="info-num"><Number :number="dealedCounts || 0"></Number></div>
        <div class="info-name">已处理总数</div>
      </div>
      <div class="info-item type2" style="margin-right: 0">
        <div class="info-num"><Number :number="dealingCounts || 0"></Number></div>
        <div class="info-name">待处理总数</div>
      </div>
    </div>
    <div class="c-1">
      <div class="c-1-text pst-1">
        <CountTo class="count" :startVal="0" :endVal="source['线下走访'] || 0" :duration="1000" />
      </div>
      <div class="c-1-text pst-2">
        <CountTo class="count" :startVal="0" :endVal="source['企业提交'] || 0" :duration="1000" />
      </div>
      <div class="c-1-text pst-3">
        <CountTo class="count" :startVal="0" :endVal="source['电话回访'] || 0" :duration="1000" />
      </div>
      <div class="c-1-text pst-4">
        <CountTo class="count" :startVal="0" :endVal="source['热线电话'] || 0" :duration="1000" />
      </div>
      <div class="c-1-text pst-5">
        <CountTo class="count" :startVal="0" :endVal="source['其他'] || 0" :duration="1000" />
      </div>
    </div>
    <div class="title-center"><div class="title-center-con">诉求使用情况</div></div>
    <div style="overflow: hidden; margin-top: 8px">
      <div class="con-2-item">
        <div class="item-left" style="margin-right: 24px;"></div>
        <div class="item-right">
          <div class="item-number"><Number :number="deptNumber"></Number></div>
          <div class="item-name">部门使用数</div>
        </div>
      </div>
      <div class="con-2-item con-2-right" style="margin-right: 0">
        <div class="item-right">
          <div class="item-number"><Number :number="cpyNumber"></Number></div>
          <div class="item-name">企业使用数</div>
        </div>
        <div class="item-left"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { CountTo } from 'vue-count-to2'
import NumberPanel from '@/views/visit/components/NumberPanel'
import Number from './Number'
import { aggregateList } from '@/api/aggregate2'
import { ref } from 'vue'
export default {
  name: 'center',
  components: {
    NumberPanel,
    Number,
    CountTo
  },
  setup: () => {
    // 加载页面数据
    const counts = ref(0)
    const dealedCounts = ref(0)
    const dealingCounts = ref(0)
    const cpyNumber = ref(0)
    const deptNumber = ref(0)
    const source = ref({})
    const fetchData = async () => {
      const params = {
        'total-needs-counts': {
          cmdList: [
            // { $project: { isDealed: { $cond: [{ $eq: ['$data.needStatus', '3'] }, 1, 0] } } },
            {
              $project: {
                isDealed: {
                  $cond: {
                    if: {
                      $or: [{ $eq: ['$data.needStatus', '3'] }, { $eq: ['$data.needStatus', '5'] }]
                    },
                    then: 1,
                    else: 0
                  }
                }
              }
            },
            { $group: { _id: 'total', counts: { $sum: 1 }, dealedCounts: { $sum: '$isDealed' } } },
            {
              $project: {
                dealing: { $subtract: ['$counts', '$dealedCounts'] },
                counts: 1,
                dealedCounts: 1
              }
            }
          ],
          templateName: 'b_console_needs'
        },
        'total-source-needs': {
          cmdList: [{ $group: { _id: '$data.source', counts: { $sum: 1 } } }],
          dicCodes: [
            {
              name: '_id',
              type: 1,
              typeKey: 'needs_source'
            }
          ],
          templateName: 'b_console_needs'
        },
        'total-dept-counts': {
          cmdList: [
            { $group: { _id: '$data.hostDeptId' } },
            { $group: { _id: 'total', counts: { $sum: 1 } } }
          ],
          templateName: 'b_console_needs'
        },
        'total-cpy-counts': {
          cmdList: [
            { $group: { _id: '$data.enterpriseId' } },
            { $group: { _id: 'total', counts: { $sum: 1 } } }
          ],
          templateName: 'b_console_needs'
        }
      }

      const res = await aggregateList(params)
      const data = res.result
      counts.value = data['total-needs-counts'][0]?.counts
      dealedCounts.value = data['total-needs-counts'][0]?.dealedCounts
      dealingCounts.value = data['total-needs-counts'][0]?.dealing
      cpyNumber.value = data['total-cpy-counts'][0]?.counts
      deptNumber.value = data['total-dept-counts'][0]?.counts
      const sourceData = {}
      data['total-source-needs'].forEach(item => {
        sourceData[item.dic__id] = item.counts
      })
      source.value = sourceData
    }
    fetchData()
    setInterval(() => {
      fetchData()
    }, 10 * 60 * 1000)

    return { counts, dealedCounts, dealingCounts, cpyNumber, deptNumber, source }
  }
}
</script>

<style scoped>
.item-left {
  float: left;
  width: 128px;
  height: 97px;
  margin-top: 10px;
  background: url('../../../assets/images/c2.png') no-repeat scroll left center;
  background-size: 100% 100%;
}
.item-right {
  float: left;
  width: 252px;
}
.con-2-right .item-right {
  margin-right: 24px;
}
.con-2-right .item-number {
  color: rgb(252, 141, 61);
  text-align: right;
  border-bottom: 1px solid rgb(252, 141, 61);
}
.con-2-right .item-name {
  text-align: right;
}
.con-2-right .item-left {
  background: url('../../../assets/images/c3.png') no-repeat scroll left center;
  background-size: 100% 100%;
}
.item-number {
  color: rgb(87, 214, 100);
  font-family: LiquidCrystal;
  font-size: 36px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 6px;
  text-align: left;
  border-bottom: 1px solid rgb(87, 214, 100);
  margin-bottom: 10px;
}
.item-name {
  color: rgb(255, 255, 255);
  font-family: Microsoft YaHei;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.con-2-item {
  float: left;
  width: 450px;
  height: 154px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: 12px;
  background-color: rgba(0, 0, 0, 0.15);
  border: 1px solid rgb(12, 94, 216);
}
.title-center {
  color: rgb(93, 229, 254);
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  background: url('../../../assets/images/h-bg-l.png') no-repeat scroll left center;
}
.title-center-con {
  background: url('../../../assets/images/h-bg-r.png') no-repeat scroll right center;
}

.info-box {
  overflow: hidden;
  margin-bottom: 16px;
}
.info-item {
  float: left;
  margin-right: 12px;
  width: 450px;
  height: 124px;
  letter-spacing: 10px;
  text-align: center;
}
.type1 {
  color: rgb(87, 214, 100);
  background: url('../../../assets/images/c4.png');
  background-size: 100% 100%;
}
.type2 {
  color: rgb(254, 142, 61);
  background: url('../../../assets/images/c5.png');
  background-size: 100% 100%;
}
.info-num {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  padding-top: 12px;
}
.info-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}
.c-1 {
  position: relative;
  width: 100%;
  height: 396px;
  margin-bottom: 12px;
  background: url('../../../assets/images/c1.jpg') no-repeat scroll center;
  background-size: 100% 100%;
  border: 1px solid rgb(12, 94, 216);
}
.c-1-text {
  position: absolute;
  width: 97px;
  height: 40px;
  text-align: center;
  color: rgb(93, 229, 254);
  font-family: Microsoft YaHei;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
}
.pst-1 {
  left: 432px;
  top: 48px;
}
.pst-2 {
  left: 142px;
  top: 172px;
}
.pst-3 {
  left: 675px;
  top: 200px;
}
.pst-4 {
  left: 198px;
  top: 322px;
}
.pst-5 {
  left: 615px;
  top: 339px;
}
</style>
