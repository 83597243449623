<template>
  <div class="n-box-item-box" :style="{'height':contentHeight?(contentHeight+'px'):'auto'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'conBox',
  props: {
    contentHeight: [String, Array]
  }
}
</script>

<style scoped>
  .n-box-item-box{
    margin-top: 8px;
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid rgb(65, 67, 84);
  }
</style>
