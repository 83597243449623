<template>
  <div class="number-box">
    <template v-for="item in unit" :key="item">
      <span class="number" :class="{'noNumber':number<Math.pow(10, unit - item)}">
        <CountTo class="count" :startVal="0" :endVal="parseInt((number%Math.pow(10, unit - item + 1))/Math.pow(10, unit - item))" :duration="1000" />
      </span>
      <span :class="{'noNumber':number<Math.pow(10, unit - item)}" v-if="(unit - item)%3 == 0 && (unit - item) != 0">,</span>
    </template>
  </div>
</template>

<script>
import { CountTo } from 'vue-count-to2'
export default {
  name: 'NumberPanel',
  components: {
    CountTo
  },
  props: {
    number: Number,
    unit: {
      type: Number,
      default: 6
    }
  }
}
</script>

<style scoped>
  @import '../../../assets/styles/font.css';
  .number-box{
    padding-top: 30px;
    height: 110px;
    margin-top: 8px;
    margin-bottom: 18px;
    color: #fff;
    line-height: 64px;
    font-size: 64px;
    text-align: center;
    background: rgba(0, 0, 0, 0.15);
    border: 1px solid rgb(12, 94, 216);
    box-sizing: border-box;
  }
  .number{
    display: inline-block;
    margin: 0 6px;
    padding: 0 3px;
    font-family: 'number';
    font-weight: 400;
    background-color: #51ABFF;
    text-shadow: 0 3px 4px rgba(0,0,0,.3);
    border: 1px solid rgb(12, 94, 216);
    border-radius: 2px;
  }
  .number-box .noNumber{
    color: #51ABFF;
  }
</style>
