<template>
  <div
    class="scroll-box"
    :ref="el => newsListRefs(el, index)"
    v-for="(item, index) in listData"
    :key="index"
    :style="{ height: item.height, transition: item.hasAnimation ? 'all 0.5s' : 'none' }"
  >
    <slot :row="item"></slot>
  </div>
</template>

<script>
import { nextTick, watch } from 'vue'

export default {
  name: 'scrollList',
  props: {
    listData: {
      type: Array,
      default() {
        return []
      }
    },
    // 滚动间隔时间（毫秒）
    interval: {
      type: Number,
      default: 3000
    }
  },
  setup: props => {
    const newsList = []
    const newsListRefs = (el, index) => {
      if (el) {
        newsList.push({
          index,
          el
        })
      }
    }
    function scrollList(data) {
      data.forEach((item, index) => {
        item.height = newsList[index].el.clientHeight + 'px'
        item.hasAnimation = false
      })
      setInterval(() => {
        data[0].hasAnimation = true
        const height = data[0].height
        data[0].height = 0
        setTimeout(() => {
          data[0].hasAnimation = false
          const item = data.splice(0, 1)[0]
          item.height = height
          data.push(item)
        }, 600)
      }, props.interval)
    }
    watch(
      () => props.listData,
      newValue => {
        nextTick(() => {
          scrollList(props.listData)
        })
      }
    )
    return { newsListRefs }
  }
}
</script>

<style scoped>
.scroll-box {
  overflow: hidden;
  transition: all 0.5s;
}
</style>
