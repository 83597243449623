<template>
    <div class="n-title">
      {{name}}
    </div>
</template>

<script>
export default {
  name: 'nTitle',
  props: {
    name: String
  }
}
</script>

<style scoped>
  .n-title{
    color: rgb(93, 229, 254);
    font-family: Source Han Sans CN;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    padding-left: 88px;
    background: url('../../../assets/images/h-bg.png') no-repeat scroll left center;
  }
</style>
