<template>
  <div class="scroll-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'webkitScroll'
}
</script>

<style scoped>
  .scroll-content{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .scroll-content::-webkit-scrollbar {
    width: 8px;
    height: 14px;
  }
  .scroll-content::-webkit-scrollbar-thumb {
    background-color: #1e75ff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 8px;
  }
  .scroll-content::-webkit-scrollbar-track-piece {
    background-color: #161e31;
  }
</style>
