<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="奖励扶持企业名单" contentHeight="844">
      <div class="news">
        <div class="news-head">
          <div class="news-head-con">
            <div class="news-name">奖项名称</div>
            <div class="news-con">企业名称</div>
          </div>
        </div>
        <div class="news-content">
          <webkitScroll>
            <scrollList :list-data="newsData">
              <template v-slot="{ row }">
                <div class="news-item">
                  <div class="news-name">{{ row.name }}</div>
                  <div class="news-con">{{ row.companyName }}</div>
                </div>
              </template>
            </scrollList>
          </webkitScroll>
        </div>
      </div>
    </boxItem>
  </div>
</template>

<script>
import scrollList from '../../visit/components/scrollList'
import boxItem from '@/views/visit/components/boxItem'
import webkitScroll from '@/views/visit/components/webkitScroll'
// import { aggregateList } from '@/api/aggregate2'
import { ref, onMounted } from 'vue'
import { getPolicyProjectApply } from '@/api/list'

export default {
  name: 'right',
  components: {
    boxItem,
    webkitScroll,
    scrollList
  },
  setup: () => {
    // 实时情报
    const newsData = ref([])
    // const cData = [
    //   {
    //     name: '发展进步奖',
    //     companyName: '青岛港和东田有限公司'
    //   },
    //   {
    //     name: '专精特新奖',
    //     companyName: '青岛港和东田有限公司'
    //   },
    //   {
    //     name: '航运产业引领奖',
    //     companyName: '青岛港和东田有限公司'
    //   }
    // ]
    onMounted(async () => {
      const res = await getPolicyProjectApply()
      const list = res?.result?.list || []
      newsData.value = list.map(d => ({
        name: d.data.projectName,
        companyName: d.data.enterpriseName
      }))

      // console.log('%c [ res ]-62', 'font-size:13px; background:pink; color:#bf2c9f;', res)
      // const arr = []
      // for (let a = 0; a < 10; a++) {
      //   for (let i = 0; i < cData.length; i++) {
      //     arr.push({
      //       name: cData[i].name,
      //       companyName: cData[i].companyName
      //     })
      //   }
      // }
      // newsData.value = arr
    })
    // const fetchData = async () => {
    //   const date = new Date()
    //   const month = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1))
    //   const params = {
    //     'needs-records':
    //       {
    //         cmdList: [
    //           { $project: { orgId: '$data.hostDeptId', source: '$data.source', cpyName: '$data.enterpriseName', status: '$data.needStatus', date: { $substr: [{ $toString: '$updatedTime' }, 0, 10] } } },
    //           { $sort: { date: -1 } },
    //           { $limit: 20 }
    //         ],
    //         dicCodes: [
    //           {
    //             name: 'orgId',
    //             type: 4
    //           },
    //           {
    //             name: 'source',
    //             type: 1,
    //             typeKey: 'needs_source'
    //           }
    //         ],
    //         templateName: 'b_console_needs'
    //       },
    //     'cMonth-dept-needs-1':
    //       {
    //         cmdList: [
    //           { $match: { updatedTime: { $gte: `ISODate(${month}-01)` }, 'data.needStatus': '3', 'data.hostDeptId': { $nin: [null, ''] } } },
    //           { $project: { orgId: '$data.hostDeptId', score: { $cond: [{ $eq: [{ $type: '$data.grossScore' }, 'string'] }, 0, '$data.grossScore'] } } },
    //           { $group: { _id: '$orgId', dealedCounts: { $sum: 1 }, score: { $avg: '$score' } } },
    //           { $sort: { score: -1, dealedCounts: -1 } }
    //         ],
    //         dicCodes: [
    //           {
    //             name: '_id',
    //             type: 4
    //           }
    //         ],
    //         templateName: 'b_console_needs'
    //       },
    //     'cMonth-dept-needs-2':
    //       {
    //         cmdList: [
    //           { $match: { 'data.needStatus': { $ne: '3' }, 'data.hostDeptId': { $nin: [null, ''] } } },
    //           { $group: { _id: '$data.hostDeptId', dealingCounts: { $sum: 1 } } },
    //           { $sort: { dealingCounts: -1 } }
    //         ],
    //         dicCodes: [
    //           {
    //             name: '_id',
    //             type: 4
    //           }
    //         ],
    //         templateName: 'b_console_needs'
    //       }
    //   }
    //   const res = await aggregateList(params)
    // }
    // 本月述求处理情况
    // fetchData()
    // setInterval(() => {
    //   fetchData()
    // }, (10 * 60 * 1000))

    return { newsData }
  }
}
</script>

<style scoped>
.assess {
  display: inline-block;
  margin: 0 2px;
  width: 11px;
  height: 10px;
  background: url('../../../assets/images/c6-1.png') no-repeat scroll left center;
  background-size: 100% 100%;
}
.assess.active {
  background: url('../../../assets/images/c6.png') no-repeat scroll left center;
  background-size: 100% 100%;
}
.news-content {
  width: 100%;
  height: calc(100% - 50px);
}
.news {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
.news-head {
  margin-bottom: 10px;
  padding: 3px 0;
  border-bottom: 1px solid rgb(12, 94, 216);
  border-top: 1px solid rgb(12, 94, 216);
}
.news-head-con {
  display: flex;
  overflow: hidden;
  padding: 6px 6px 6px 24px;
  background-color: #0c5ed8;
}
.news-item {
  display: flex;
  overflow: hidden;
  padding: 6px 6px 6px 24px;
  background-color: #373942;
  margin-bottom: 6px;
}
.news-name {
  float: left;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 24px;
}
.news-con {
  float: left;
  width: 200px;
  flex: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.td-1 {
  float: left;
  width: 60px;
}
.td-2 {
  float: left;
  width: 119px;
}
.td-3 {
  float: left;
  width: 80px;
}
.td-4 {
  float: left;
  width: 80px;
}
.td-5 {
  float: left;
  width: 86px;
}
.chartAppeal-con {
  position: absolute;
  left: 50%;
  top: 0;
  pointer-events: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}
.chartAppeal-num {
  font-size: 40px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #0052d9;
  line-height: 47px;
}
.chartAppeal-text {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 106, 244, 0.5);
  line-height: 14px;
}
.len-item {
  overflow: hidden;
  padding-bottom: 5px;
  padding-left: 8px;
  margin-bottom: 8px;
  margin-right: 40px;
  border-bottom: 1px solid #646779;
}
.len-item-icon {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  margin-top: 5px;
  margin-right: 7px;
}
.len-item-name {
  float: left;
  color: rgb(183, 186, 190);
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.len-item-text {
  float: left;
  width: 120px;
  color: rgb(255, 255, 255);
  font-family: Source Han Sans CN;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
</style>
