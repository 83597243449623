<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="历史趋势" contentHeight="256">
      <VEchart :option="historyOption" />
    </boxItem>
    <boxItem title="部门统计" contentHeight="562">
      <webkitScroll>
        <div style="height: 800px">
          <VEchart :option="visitOption" />
        </div>
      </webkitScroll>
    </boxItem>
  </div>
</template>

<script>
import boxItem from '@/views/visit/components/boxItem'
import webkitScroll from '@/views/visit/components/webkitScroll'
import { aggregateList } from '@/api/aggregate2'
import { ref } from 'vue'
import * as echarts from 'echarts'
export default {
  name: 'left',
  components: {
    boxItem,
    webkitScroll
  },
  setup: () => {
    // 历史趋势
    const historyOption = ref({})
    function history(data) {
      historyOption.value = {
        grid: {
          top: 40,
          bottom: 30
        },
        legend: {
          show: true,
          top: 10,
          right: 30,
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#D6FDFF'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#D6FDFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: '新增',
            data: data.seriesData.add,
            type: 'line',
            symbol: 'none',
            smooth: true,
            itemStyle: {
              color: '#019CFF'
            },
            lineStyle: {
              width: 2
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(1, 156, 255, 0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(33, 35, 46, 0)'
                }
              ])
            }
          },
          {
            name: '已处理',
            data: data.seriesData.processed,
            type: 'line',
            symbol: 'none',
            smooth: true,
            itemStyle: {
              color: 'rgb(87, 214, 100)'
            },
            lineStyle: {
              width: 2
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(87, 214, 100, 0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(33, 35, 46, 0)'
                }
              ])
            }
          }
        ]
      }
    }

    // 部门走访
    const visitOption = ref({})
    function visit(data) {
      visitOption.value = {
        grid: {
          left: 120,
          top: 70,
          bottom: 30
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: true,
          top: 20,
          textStyle: {
            color: '#fff'
          }
        },
        yAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgb(65, 67, 84)']
            }
          },
          data: data.xData
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            color: '#D6FDFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgb(65, 67, 84)'],
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '新增',
            data: data.seriesData.add,
            type: 'bar',
            showBackground: false,
            barMaxWidth: 8,
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgb(12, 94, 216)'
            }
          },
          {
            name: '已处理',
            data: data.seriesData.processed,
            type: 'bar',
            showBackground: false,
            barMaxWidth: 8,
            label: {
              show: false
            },
            itemStyle: {
              color: 'rgb(87, 214, 100)'
            }
          }
        ]
      }
    }

    const fetchData = async () => {
      const params = {
        'his-needs-new': {
          cmdList: [
            { $project: { date: { $substr: [{ $toString: '$createdTime' }, 0, 7] } } },
            { $group: { _id: '$date', count: { $sum: 1 } } },
            { $sort: { _id: -1 } },
            { $limit: 12 }
          ],
          templateName: 'b_console_needs'
        },
        'his-needs-done': {
          cmdList: [
            { $match: { 'data.needStatus': '3' } },
            { $project: { date: { $substr: [{ $toString: '$updatedTime' }, 0, 7] } } },
            { $group: { _id: '$date', count: { $sum: 1 } } },
            { $sort: { _id: -1 } },
            { $limit: 12 }
          ],

          templateName: 'b_console_needs'
        },
        'total-dep-needs': {
          cmdList: [
            { $match: { 'data.hostDeptId': { $nin: ['', null] } } },
            {
              $project: {
                hostDeptId: '$data.hostDeptId',
                isDealed: { $cond: [{ $eq: ['$data.needStatus', '3'] }, 1, 0] }
              }
            },
            {
              $group: {
                _id: '$hostDeptId',
                newCount: { $sum: 1 },
                dealCount: { $sum: '$isDealed' }
              }
            },
            { $sort: { newCount: 1 } }
          ],
          dicCodes: [
            {
              name: '_id',
              type: 4
            }
          ],
          templateName: 'b_console_needs'
        }
      }
      const res = await aggregateList(params)
      // console.log(res)
      // 历史趋势
      const x = {}
      res.result['his-needs-new'].forEach(item => {
        x[item.id] = {
          add: item
        }
      })
      res.result['his-needs-done'].forEach(item => {
        if (x[item.id]) {
          x[item.id].processed = item
        } else {
          x[item.id] = {
            processed: item
          }
        }
      })
      const historyArr = Object.keys(x).sort((a, b) => {
        return a.localeCompare(b)
      })
      const historyData = {
        xData: [],
        seriesData: {
          add: [],
          processed: []
        }
      }
      historyArr.forEach(item => {
        historyData.xData.push(item)
        historyData.seriesData.add.push(x[item].add?.count)
        historyData.seriesData.processed.push(x[item].processed?.count)
      })
      // console.log(historyData)
      history(historyData)

      // 部门统计
      const visitData = {
        xData: [],
        seriesData: {
          add: [],
          processed: []
        }
      }
      res.result['total-dep-needs'].forEach(item => {
        visitData.xData.push(item.dic__id)
        visitData.seriesData.add.push(item.newCount)
        visitData.seriesData.processed.push(item.dealCount)
      })
      // console.log(visitData)
      visit(visitData)
      // console.log(res)
    }
    fetchData()
    setInterval(() => {
      fetchData()
    }, 10 * 60 * 1000)

    return { historyOption, visitOption }
  }
}
</script>

<style scoped>
.n-left-box {
}
</style>
