<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="政策文件" contentHeight="259">
      <div class="chart-box">
        <div class="chart-flie">
          <div class="chart-box" style=" z-index: 2;">
            <VEchart  :option="flieOption" />
          </div>
        </div>
      </div>
    </boxItem>
    <boxItem style="margin-bottom: 12px" title="政策解读" contentHeight="259">
      <div class="chart-box">
        <div class="chart-read">
          <div class="chart-box" style=" z-index: 2;">
            <VEchart  :option="readOption" />
          </div>
        </div>
      </div>
    </boxItem>
    <boxItem style="margin-bottom: 12px" title="实时浏览量" contentHeight="226">
      <div class="chart-box flex-x" style="padding: 20px; 0">
        <div style="flex: 1;display: flex;flex-direction: column;padding: 0 16px;">
          <div class="c-t">政策文件</div>
          <div style="flex: 1;">
            <VEchart :option="flieNumOption" />
          </div>
        </div>
        <div style="width: 1px;height: 100%;background: rgba(204, 253, 255, 0.3);"></div>
        <div style="flex: 1;display: flex;flex-direction: column;padding: 0 16px;">
          <div class="c-t">政策解读</div>
          <div style="flex: 1;">
            <VEchart :option="readNumOption" />
          </div>
        </div>
      </div>
    </boxItem>
  </div>
</template>

<script>
import boxItem from '@/views/visit/components/boxItem'
import { aggregateList } from '@/api/aggregate2'
import { ref } from 'vue'
import * as echarts from 'echarts'
export default {
  name: 'left',
  components: {
    boxItem
  },
  setup: () => {
    // 政策文件
    const flieOption = ref({})
    const fileColor = ['#009CFB', '#00FEFF', '#00FE65', '#F5FF46']
    function flie (data) {
      flieOption.value = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: fileColor,
        legend: {
          show: false
        },
        series: [
          {
            name: '政策文件',
            type: 'pie',
            radius: ['52%', '68%'],
            itemStyle: {
              // borderWidth: 2,
              // borderColor: '#000',
              shadowBlur: 8,
              shadowColor: 'rgba(255, 255, 255, 0.5)'
            },
            label: {
              formatter: '{b|{c}}\n{b} ',
              position: 'outer',
              lineHeight: 16,
              minMargin: 5,
              alignTo: 'edge',
              edgeDistance: '10%',
              rich: {
                b: {
                  lineHeight: 18,
                  fontSize: 16,
                  align: 'left'
                }
              }
            },
            data: data.map((item, index) => {
              item.label = {
                color: fileColor[index]
              }
              return item
            })
          }
        ]
      }
    }
    // 政策解读
    const readOption = ref({})
    function read (data) {
      readOption.value = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#FDF736', '#00C18E', '#009CFB', '#30E4FE'],
        legend: {
          show: false
        },
        series: [
          {
            name: '政策解读',
            type: 'pie',
            radius: ['40%', '68%'],
            label: {
              formatter: '{b}\n{b|{d}%} ',
              position: 'outer',
              lineHeight: 16,
              minMargin: 5,
              alignTo: 'edge',
              edgeDistance: '10%',
              rich: {
                b: {
                  lineHeight: 18,
                  fontSize: 16,
                  align: 'left'
                }
              }
            },
            data: data.map((item, index) => {
              item.label = {
                color: fileColor[index]
              }
              return item
            })
          }
        ]
      }
    }
    const flieNumData = {
      xData: ['国家级', '山东省', '青岛市', '市南区'],
      seriesData: [2543, 2456, 2165, 1898]
    }
    // 政策文件-浏览量
    const flieNumOption = ref({})
    function flieNum (data) {
      flieNumOption.value = {
        grid: {
          left: 46,
          top: 20,
          bottom: 10
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            color: '#D6FDFF'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: '政策文件',
            data: data.seriesData,
            type: 'bar',
            barMaxWidth: 16,
            label: {
              show: true,
              position: 'right',
              color: '#fff'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: '#10EAD4'
                },
                {
                  offset: 1,
                  color: 'rgba(21, 135, 228, 0)'
                }
              ])
            }
          }
        ]
      }
    }
    flieNum(flieNumData)
    const readNumData = {
      xData: ['文字解读', '图文解读', '视频解读', '社会化解读'],
      seriesData: [28465, 34587, 45867, 39859]
    }
    // 政策解读
    const readNumOption = ref({})
    function readNum (data) {
      readNumOption.value = {
        grid: {
          left: 72,
          top: 20,
          bottom: 10
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            color: '#D6FDFF'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: '政策解读',
            data: data.seriesData,
            type: 'bar',
            barMaxWidth: 16,
            label: {
              show: true,
              position: 'right',
              color: '#fff'
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: 'rgba(0, 255, 164, 1)'
                },
                {
                  offset: 1,
                  color: 'rgba(21, 135, 228, 0)'
                }
              ])
            }
          }
        ]
      }
    }
    readNum(readNumData)
    const fetchData = async () => {
      const params = {
        readCounts: {
          cmdList: [
            { $match: {} },
            { $unwind: '$data.unscrambleType' },
            { $group: { _id: '$data.unscrambleType', counts: { $sum: 1 } } },
            { $sort: { _id: -1 } }
          ],
          templateName: 'b_console_unscramble'
        },
        readNumCounts: {
          cmdList: [
            { $match: {} },
            { $unwind: '$data.unscrambleType' },
            { $group: { _id: '$data.unscrambleType', counts: { $sum: '$data.pageView' } } },
            { $sort: { _id: -1 } }
          ],
          templateName: 'b_console_unscramble'
        },
        flieCounts: {
          cmdList: [
            { $match: {} },
            { $unwind: '$data.policyLevel' },
            { $group: { _id: '$data.policyLevel', counts: { $sum: 1 } } },
            { $sort: { _id: -1 } }
          ],
          templateName: 'b_console_policy_document'
        }
        // imgCounts: {
        //   cmdList: [
        //     { $match: { 'data.unscrambleType': { $regex: '2' } } },
        //     { $group: { _id: '', counts: { $sum: 1 } } }
        //   ],
        //   templateName: 'b_console_unscramble'
        // },
        // videoCounts: {
        //   cmdList: [
        //     { $match: { 'data.unscrambleType': { $regex: '3' } } },
        //     { $group: { _id: '', counts: { $sum: 1 } } }
        //   ],
        //   templateName: 'b_console_unscramble'
        // },
        // societyCounts: {
        //   cmdList: [
        //     { $match: { 'data.unscrambleType': { $regex: '4' } } },
        //     { $group: { _id: '', counts: { $sum: 1 } } }
        //   ],
        //   templateName: 'b_console_unscramble'
        // },
      }
      const res = await aggregateList(params)
      // console.log(res)
      // 政策文件
      const flieData = [
        {
          name: '市南区',
          value: res.result.flieCounts[0].counts || 0
        },
        {
          name: '青岛市',
          value: res.result.flieCounts[1].counts || 0
        },
        {
          name: '山东省',
          value: res.result.flieCounts[2].counts || 0
        },
        {
          name: '国家级',
          value: res.result.flieCounts[3].counts || 0
        }
      ]
      // console.log(flieData)
      flie(flieData)
      // 政策解读
      const readData = [
        {
          name: '文字解读',
          value: res.result.readCounts[0].counts || 0
        },
        {
          name: '图文解读',
          value: res.result.readCounts[1].counts || 0
        },
        {
          name: '视频解读',
          value: res.result.readCounts[2].counts || 0
        },
        {
          name: '社会化解读',
          value: res.result.readCounts[3].counts || 0
        }
      ]
      // console.log(flieData)
      read(readData)
      // console.log(historyData)
      // flie(historyData)
    }
    fetchData()
    setInterval(() => {
      fetchData()
    }, (10 * 60 * 1000))

    return { flieOption, readOption, flieNumOption, readNumOption }
  }
}
</script>

<style scoped lang="less">
.c-t{
  font-size: 16px;
  color: #30E4FE;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 27px;
}
.flex-x{
  display: flex;
  flex-direction: row;
}
.chart-box{
  width: 100%;
  height: 100%;
  position: relative;
}
.chart-flie{
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/policy/p-7.png') no-repeat scroll center;
  background-size: 195px 196px;
}
.chart-flie::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url('../../../assets/images/policy/p-5.png') no-repeat scroll center;
  background-size: 106px 106px;
  animation: boxRotate infinite 5s linear;
}
.chart-flie::before{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url('../../../assets/images/policy/p-6.png') no-repeat scroll center;
  background-size: 30px 30px;
}

@keyframes boxRotate{
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.chart-read{
  width: 100%;
  height: 100%;
  background: url('../../../assets/images/policy/p-8.png') no-repeat scroll center;
  background-size: 205px 204px;
}
.chart-read::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url('../../../assets/images/policy/p-9.png') no-repeat scroll center;
  background-size: 90px 90px;
}

</style>
