<template>
  <div class="needs-wrap">
    <div class="needs-box">
      <div class="n-head">
        <svg style="font-size: 24px;font-weight: bold;width: 160px;height: 80px;letter-spacing: 4px;">
          <defs>
            <linearGradient id="grad" x1="50%" y1="0%" x2="50%" y2="100%">
              <stop offset="0%" style="stop-color:#5DE5FE; stop-opacity:1" />
              <stop offset="20%" style="stop-color:#5DE5FE; stop-opacity:1" />
              <stop offset="45%" style="stop-color:#5DE5FE; stop-opacity:1" />
              <stop offset="100%" style="stop-color:#0346A9; stop-opacity:1" />
            </linearGradient>
          </defs>
          <text x="80" y="48" text-anchor="middle" fill="url(#grad)">企业走访</text>
        </svg>
      </div>
      <div class="n-con">
        <div class="n-left">
          <left></left>
        </div>
        <div class="n-right">
          <right></right>
        </div>
        <div class="n-center">
          <center></center>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import useLoading from '@/hooks/useLoading'
import left from './components/left'
import right from './components/right'
import center from './components/center'
export default {
  name: '',
  components: {
    left,
    right,
    center
  },
  setup: () => {
    const { hidLoading } = useLoading()
    hidLoading()

    return {}
  }
}
</script>

<style lang="less" scoped>
.needs-wrap {
  width: 100%;
  height: 100%;
  background: rgb(39, 41, 54);
  overflow: hidden;
}
.needs-box{
  width: 100%;
  height: 100%;
  background: url("../../assets/images/title.png") no-repeat scroll center top;
  background-size: 1920px 179px;
}
  .n-head{
    text-align: center;
    height: 88px;
  }
  .n-con{
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
  }
  .n-left{
    float: left;
    width: 480px;
    height: 100%;
    margin-left: 12px;
  }
  .n-right{
    float: right;
    width: 480px;
    height: 100%;
    margin-right: 12px;
  }
  .n-center{
    height: 100%;
    margin: 0 504px;
  }
</style>
