// 获取常用时间
import dayjs from 'dayjs'

export const LAST_7_DAYS = [
  dayjs()
    .subtract(7, 'day')
    .format('YYYY-MM-DD'),
  dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
]

export const LAST_30_DAYS = [
  dayjs()
    .subtract(30, 'day')
    .format('YYYY-MM-DD'),
  dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
]

export const CURRENT_YEAR = dayjs(new Date()).format('YYYY')
export const CURRENT_DATE = dayjs(new Date()).format('YYYY-MM-DD')

export const CURRENT_DATE_START = dayjs(new Date())
  .startOf('month')
  .format('YYYY-MM-DD')

export const CURRENT_DATE_END = dayjs(new Date())
  .endOf('month')
  .format('YYYY-MM-DD')

export const CURRENT_TIME = dayjs(new Date()).format('YYYY-MM-DD HH:mm')
export const CURRENT_TIME_SECS = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
export const CURRENT_TIME_SECS_END = dayjs(new Date())
  .endOf('day')
  .format('YYYY-MM-DD HH:mm:ss')

export const CURRENT_MONTH = dayjs(new Date()).format('YYYY-MM')

export const NEXT_MONTH = dayjs(new Date())
  .add(1, 'month')
  .format('YYYY-MM')

export const LAST_MONTH = month =>
  dayjs(month)
    .subtract(1, 'month')
    .format('YYYY-MM')
export const NEXT_MONTH2 = month =>
  dayjs(month)
    .add(1, 'month')
    .format('YYYY-MM')
// 比较两个日期大小
export const isBefore = (par1, par2) => {
  return dayjs(par1).isBefore(dayjs(par2))
}
// 比较两个日期大小
export const isAfter = (par1, par2) => {
  return dayjs(par1).isAfter(dayjs(par2))
}

// 指定日期加多少天
export const addDay = (day, num) => {
  return dayjs(day)
    .add(num, 'day')
    .format('YYYY-MM-DD')
}

// 指定日期减多少天
export const subtractDay = (day, num) => {
  return dayjs(day)
    .subtract(num, 'day')
    .format('YYYY-MM-DD')
}

export const getFirstEndDay = (year, type) => {
  return [
    dayjs(year)
      .startOf(type)
      .format('YYYY-MM-DD'),
    dayjs(year)
      .endOf(type)
      .format('YYYY-MM-DD')
  ]
}

// 返回指定月份的第一天
export const getFirstDayOfMonth = yyyyMM => {
  return dayjs(yyyyMM)
    .startOf('month')
    .format('YYYY-MM-DD')
}
// 返回指定月份的最后一天
export const getEndDayOfMonth = yyyyMM => {
  return dayjs(yyyyMM)
    .endOf('month')
    .format('YYYY-MM-DD')
}

// 返回指定月份的下个月第一天
export const getFirstDayOfNexMonth = yyyyMM => {
  return dayjs(yyyyMM)
    .add(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD')
}

// 返回指定月份的下个月
export const getNexMonth = yyyyMM => {
  return dayjs(yyyyMM)
    .add(1, 'month')
    .startOf('month')
    .format('YYYY-MM')
}

// 两个日期的插值
export const diff = (date1, date2, unit = 'day') => {
  return dayjs(date1).diff(dayjs(date2), unit)
}
