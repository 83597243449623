<template>
    <div class="n-box-item">
      <nTitle :name="title"></nTitle>
      <conBox :contentHeight="contentHeight">
        <slot></slot>
      </conBox>
    </div>
</template>

<script>
import nTitle from './title'
import conBox from './conBox'
export default {
  name: 'boxItem',
  components: {
    nTitle,
    conBox
  },
  props: {
    title: String,
    contentHeight: [String, Array]
  }
}
</script>

<style scoped>
</style>
