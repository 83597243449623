<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="实时情报" contentHeight="256">
      <div class="news">
        <div class="news-head">
          <div class="news-head-con">
            <div class="news-name">发布日期</div>
            <div class="news-con">文章标题</div>
          </div>
        </div>
        <div class="news-content">
          <webkitScroll>
            <scrollList :list-data="newsData">
              <template v-slot="{row}">
                <div class="news-item">
                  <div class="news-name">{{row.date}}</div>
                  <div class="news-con" v-if="row.status == '1'">{{`${row.cpyName}通过${row.dic_source}通道提交了诉求`}}</div>
                  <div class="news-con" v-else>{{`${row.dic_orgId}处理了${row.cpyName}提交的诉求`}}</div>
                </div>
              </template>
            </scrollList>
          </webkitScroll>
        </div>
      </div>
    </boxItem>
    <boxItem style="margin-bottom: 12px" title="本月部门诉求处理情况" contentHeight="562">
      <div class="news" style="text-align: center">
        <div class="news-head">
          <div class="news-head-con" style="padding: 6px">
            <div class="td-1">排名</div>
            <div class="td-2">部门</div>
            <div class="td-3">已处理</div>
            <div class="td-4">待处理</div>
            <div class="td-5">平均评价</div>
          </div>
        </div>
        <div class="news-content">
          <webkitScroll>
            <div class="news-item" style="padding: 6px" v-for="(item, index) in appealData" :key="index">
              <div class="td-1">NO.{{index + 1}}</div>
              <div class="td-2">{{item.dic__id}}</div>
              <div class="td-3" style="color: rgb(87, 214, 100)">{{item.dealedCounts}}件</div>
              <div class="td-4" style="color: rgb(255, 90, 90)">{{item.dealingCounts}}件</div>
              <div class="td-5">
                <div class="assess" :class="{'active': (5 - item.score) <= (aItem - 1)}" v-for="aItem in 5" :key="aItem"></div>
              </div>
            </div>
          </webkitScroll>
        </div>
      </div>
    </boxItem>
  </div>
</template>

<script>
import scrollList from '../../visit/components/scrollList'
import boxItem from '@/views/visit/components/boxItem'
import webkitScroll from '@/views/visit/components/webkitScroll'
import { aggregateList } from '@/api/aggregate2'
import { ref } from 'vue'
export default {
  name: 'right',
  components: {
    boxItem,
    webkitScroll,
    scrollList
  },
  setup: () => {
    // 实时情报
    const newsData = ref([])

    /**
     * JSON数组排序
     * @param type：排序方式
     * @param arr：json数组
     * @param key：需要排序的key
     * @returns {arr}：返回排序好的数组
     */
    function jsonArrSort (type, arr, key) {
      arr.sort((a, b) => {
        if (type === 'up') {
          return a[key] - b[key]
        } else if (type === 'down') {
          return b[key] - a[key]
        }
      })
      return arr
    }

    /**
     * JSON数组第二权重指标排序
     * @param type：第二权重指标排序方式
     * @param arr
     * @param key1：第一权重指标
     * @param key2：第二权重指标
     * @returns {arr}
     */
    function jsonArrTwoKeySort (type, arr, key1, key2) {
      const newArr = []
      let sameArr = []
      arr.forEach((item, index) => {
        if ((arr.length > 1) && (index < arr.length - 1) && (item[key1] === arr[index + 1][key1])) {
          sameArr.push(item)
        } else {
          if (sameArr.length > 0) {
            sameArr.push(item)
            newArr.push(jsonArrSort(type, sameArr, key2))
            sameArr = []
          } else {
            newArr.push(item)
          }
        }
      })
      arr = newArr
      return newArr
    }

    /**
     * JSON数组多指标排序
     * @param arr
     * @param keyArr:[{sortType: up升序 down降序, key 需要排序的指标名称}]
     */
    function jsonArrMoreKeySort (arr, keyArr) {
      jsonArrSort(keyArr[0].sortType, arr, keyArr[0].key)
      if (keyArr.length > 1) {
        for (let i = 0; i < keyArr.length - 1; i++) {
          jsonArrTwoKeySort(keyArr[i + 1].sortType, arr, keyArr[i].key, keyArr[i + 1].key)
        }
      }
    }
    const fetchData = async () => {
      const date = new Date()
      const month = date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1))
      const params = {
        'needs-records':
          {
            cmdList: [
              { $project: { orgId: '$data.hostDeptId', source: '$data.source', cpyName: '$data.enterpriseName', status: '$data.needStatus', date: { $substr: [{ $toString: '$updatedTime' }, 0, 10] } } },
              { $sort: { date: -1 } },
              { $limit: 20 }
            ],
            dicCodes: [
              {
                name: 'orgId',
                type: 4
              },
              {
                name: 'source',
                type: 1,
                typeKey: 'needs_source'
              }
            ],
            templateName: 'b_console_needs'
          },
        'cMonth-dept-needs-1':
          {
            cmdList: [
              { $match: { updatedTime: { $gte: `ISODate(${month}-01)` }, 'data.needStatus': '3', 'data.hostDeptId': { $nin: [null, ''] } } },
              { $project: { orgId: '$data.hostDeptId', score: { $cond: [{ $eq: [{ $type: '$data.grossScore' }, 'string'] }, 0, '$data.grossScore'] } } },
              { $group: { _id: '$orgId', dealedCounts: { $sum: 1 }, score: { $avg: '$score' } } },
              { $sort: { score: -1, dealedCounts: -1 } }
            ],
            dicCodes: [
              {
                name: '_id',
                type: 4
              }
            ],
            templateName: 'b_console_needs'
          },
        'cMonth-dept-needs-2':
          {
            cmdList: [
              { $match: { 'data.needStatus': { $ne: '3' }, 'data.hostDeptId': { $nin: [null, ''] } } },
              { $group: { _id: '$data.hostDeptId', dealingCounts: { $sum: 1 } } },
              { $sort: { dealingCounts: -1 } }
            ],
            dicCodes: [
              {
                name: '_id',
                type: 4
              }
            ],
            templateName: 'b_console_needs'
          }
      }
      const res = await aggregateList(params)
      // 实时情报
      newsData.value = res.result['needs-records']
      // 本月述求处理情况
      const x = {}
      res.result['cMonth-dept-needs-1'].forEach(item => {
        x[item.dic__id] = item
        x[item.dic__id].dealingCounts = 0
      })
      res.result['cMonth-dept-needs-2'].forEach(item => {
        if (x[item.dic__id]) {
          x[item.dic__id].dealingCounts = item.dealingCounts
        } else {
          // eslint-disable-next-line no-prototype-builtins
          if (!(item.hasOwnProperty('score'))) {
            item.score = 0
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!(item.hasOwnProperty('dealedCounts'))) {
            item.dealedCounts = 0
          }
          x[item.dic__id] = item
        }
      })
      const arr = []
      for (const key in x) {
        arr.push(x[key])
      }
      jsonArrMoreKeySort(arr, [
        {
          key: 'score',
          sortType: 'up'
        }, {
          key: 'dealedCounts',
          sortType: 'up'
        }, {
          key: 'dealingCounts',
          sortType: 'up'
        }
      ])
      appealData.value = arr
    }
    // 本月述求处理情况
    const appealData = ref([])
    fetchData()
    setInterval(() => {
      fetchData()
    }, (10 * 60 * 1000))

    return { newsData, appealData }
  }
}
</script>

<style scoped>
  .assess{
    display: inline-block;
    margin: 0 2px;
    width: 11px;
    height: 10px;
    background: url('../../../assets/images/c6-1.png') no-repeat scroll left center;
    background-size: 100% 100%;
  }
  .assess.active{
    background: url('../../../assets/images/c6.png') no-repeat scroll left center;
    background-size: 100% 100%;
  }
  .news-content{
    width: 100%;
    height: calc(100% - 50px);
  }
  .news{
    width: 100%;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }
  .news-head{
    margin-bottom: 10px;
    padding: 3px 0;
    border-bottom: 1px solid rgb(12, 94, 216);
    border-top: 1px solid rgb(12, 94, 216);
  }
  .news-head-con{
    overflow: hidden;
    padding: 6px 6px 6px 24px;
    background-color: #0C5ED8;
  }
  .news-item{
    overflow: hidden;
    padding: 6px 6px 6px 24px;
    background-color: #373942;
    margin-bottom: 6px;
  }
  .news-name{
    float: left;
    width: 100px;
  }
  .news-con{
    float: left;
    width: 308px;
  }
  .td-1{
    float: left;
    width: 60px;
  }
  .td-2{
    float: left;
    width: 119px;
  }
  .td-3{
    float: left;
    width: 80px;
  }
  .td-4{
    float: left;
    width: 80px;
  }
  .td-5{
    float: left;
    width: 86px;
  }
  .chartAppeal-con {
    position: absolute;
    left: 50%;
    top: 0;
    pointer-events: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
  }
  .chartAppeal-num {
    font-size: 40px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #0052d9;
    line-height: 47px;
  }
  .chartAppeal-text {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(34, 106, 244, 0.5);
    line-height: 14px;
  }
  .len-item{
    overflow: hidden;
    padding-bottom: 5px;
    padding-left: 8px;
    margin-bottom: 8px;
    margin-right: 40px;
    border-bottom: 1px solid #646779;
  }
  .len-item-icon{
    float: left;
    width: 12px;
    height: 12px;
    border-radius: 1px;
    margin-top: 5px;
    margin-right: 7px;
  }
  .len-item-name{
    float: left;
    color: rgb(183, 186, 190);
    font-family: Source Han Sans CN;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  .len-item-text{
    float: left;
    width: 120px;
    color: rgb(255, 255, 255);
    font-family: Source Han Sans CN;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }
</style>
