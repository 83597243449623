<template>
  <div>
    <div class="title-center"><div class="title-center-con">政策奖励</div></div>
    <div class="flex-sb bg-1" style="margin-top:10px">
      <div class="jl-name">奖励资金（万元）</div>
      <div class="jl-number"><Number :number="51000 || 0"></Number></div>
    </div>
    <div class="flex-sb bg-2">
      <div class="jl-name">奖励企业（家/次）</div>
      <div class="jl-number" style="color:#00FFA4"><Number :number="654 || 0"></Number></div>
    </div>
    <div class="title-center"><div class="title-center-con">免申即享项目</div></div>
    <conBoxVue :contentHeight="150">
      <div style="padding: 16px 20px">
        <div class="info-box">
          <div class="info-item type1">
            <div class="info-name">免申即享奖励金额 (万元)</div>
            <div class="info-num"><Number :number="dealedCounts || 0"></Number></div>
          </div>
          <div class="info-item type2" style="margin-right: 0">
            <div class="info-name">免申即享奖励企业(家/次)</div>
            <div class="info-num"><Number :number="dealingCounts || 0"></Number></div>
          </div>
        </div>
      </div>
    </conBoxVue>

    <conBoxVue :contentHeight="415">
      <ul class="flie-list">
        <div class="title-center project-apply"><div class="title-center-con">申报项目</div></div>
        <li class="flie-item" v-for="(item, index) in listData" :key="index">
          <div class="tags-box">
            <span v-for="(aitem, aindex) in item.tags" :key="'tag' + aindex">{{ aitem }}</span>
          </div>
          <div class="flie-name">{{ item.name }}</div>
          <div class="flie-info">发布时间：{{ item.time }}</div>
        </li>
      </ul>
    </conBoxVue>
  </div>
</template>

<script>
import Number from './Number'
import conBoxVue from '@/views/visit/components/conBox.vue'
// import { aggregateList } from '@/api/aggregate2'
// import { getPolicyDocumentPageList } from '@/api/list'
import { ref } from 'vue'
export default {
  name: 'center',
  components: {
    Number,
    conBoxVue
  },
  setup: () => {
    // 加载页面数据
    const counts = ref(0)
    const dealedCounts = ref(21000)
    const dealingCounts = ref(306)
    const cpyNumber = ref(0)
    const deptNumber = ref(0)
    const source = ref({})
    // const fetchData = async () => {
    //   const params = {
    //     'total-needs-counts':
    //       {
    //         cmdList: [
    //           { $project: { isDealed: { $cond: [{ $eq: ['$data.needStatus', '3'] }, 1, 0] } } },
    //           { $group: { _id: 'total', counts: { $sum: 1 }, dealedCounts: { $sum: '$isDealed' } } },
    //           { $project: { dealing: { $subtract: ['$counts', '$dealedCounts'] }, counts: 1, dealedCounts: 1 } }
    //         ],
    //         templateName: 'b_console_needs'
    //       },
    //     'total-source-needs':
    //       {
    //         cmdList: [
    //           { $group: { _id: '$data.source', counts: { $sum: 1 } } }
    //         ],
    //         dicCodes: [
    //           {
    //             name: '_id',
    //             type: 1,
    //             typeKey: 'needs_source'
    //           }
    //         ],
    //         templateName: 'b_console_needs'
    //       },
    //     'total-dept-counts':
    //       {
    //         cmdList: [
    //           { $group: { _id: '$data.hostDeptId' } },
    //           { $group: { _id: 'total', counts: { $sum: 1 } } }
    //         ],
    //         templateName: 'b_console_needs'
    //       },
    //     'total-cpy-counts':
    //       {
    //         cmdList: [
    //           { $group: { _id: '$data.enterpriseId' } },
    //           { $group: { _id: 'total', counts: { $sum: 1 } } }
    //         ],
    //         templateName: 'b_console_needs'
    //       }
    //   }

    //   const res = await aggregateList(params)
    //   const data = res.result
    //   counts.value = data['total-needs-counts'][0]?.counts
    //   dealedCounts.value = data['total-needs-counts'][0]?.dealedCounts
    //   dealingCounts.value = data['total-needs-counts'][0]?.dealing
    //   cpyNumber.value = data['total-cpy-counts'][0]?.counts
    //   deptNumber.value = data['total-dept-counts'][0]?.counts
    //   const sourceData = {}
    //   data['total-source-needs'].forEach(item => {
    //     sourceData[item.dic__id] = item.counts
    //   })
    //   source.value = sourceData
    // }
    // fetchData()
    const listData = ref([
      {
        tags: ['交水发（2022）104号', '工业'],
        name: '交通运输部 国家发展改革委关于阶段性降低货物港务费收费标准的通知',
        time: '2022-05-09'
      },
      {
        tags: ['交水发（2022）104号', '工业'],
        name: '交通运输部 国家发展改革委关于阶段性降低货物港务费收费标准的通知',
        time: '2022-05-09'
      },
      {
        tags: ['交水发（2022）104号', '工业'],
        name: '交通运输部 国家发展改革委关于阶段性降低货物港务费收费标准的通知',
        time: '2022-05-09'
      }
    ])
    // const getFileData = async () => {
    //   const params = {
    //     conditions: { 'data.publishStatus': '1' },
    //     pageInfo: {
    //       pageNum: 1,
    //       pageSize: 3
    //     },
    //     sort: { 'data.dateOf': -1 },
    //     templateName: 'b_console_policy_document'
    //   }
    //   const res = await getPolicyDocumentPageList(params)
    //   const data = res.result
    //   listData.value = data
    // }
    // getFileData()
    // setInterval(() => {
    //   fetchData()
    // }, (10 * 60 * 1000))

    return { counts, dealedCounts, dealingCounts, cpyNumber, deptNumber, source, listData }
  }
}
</script>

<style scoped lang="less">
.flie-item {
  padding: 14px 20px;
  margin-bottom: 12px;
  background-color: rgba(0, 133, 255, 0.15);
  .tags-box {
    margin-bottom: 10px;
    font-size: 11px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #d6fdff;
    line-height: 20px;
    span {
      display: inline-block;
      padding: 2px 11px;
      margin-right: 13px;
      background: rgba(0, 80, 139, 1);
    }
  }
  .flie-name {
    font-size: 16px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #d6fdff;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  .flie-info {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #d6fdff;
    line-height: 1.5;
  }
}
.flex-sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bg-1 {
  width: 100%;
  height: 100px;
  background: url('../../../assets/images/policy/p-1.png') no-repeat scroll left center;
  background-size: 100% 100%;
  margin-bottom: 20px;
}
.bg-2 {
  width: 100%;
  height: 100px;
  background: url('../../../assets/images/policy/p-2.png') no-repeat scroll left center;
  background-size: 100% 100%;
  margin-bottom: 11px;
}
.jl-name {
  color: #30e4fe;
  font-size: 28px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
  line-height: 27px;
  margin-left: 36px;
}
.jl-number {
  font-size: 60px;
  font-family: LiquidCrystal-Regular, LiquidCrystal;
  font-weight: 400;
  color: #30e4fe;
  line-height: 94px;
  margin-right: 95px;
}
.title-center {
  color: rgb(93, 229, 254);
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  background: url('../../../assets/images/h-bg-l.png') no-repeat scroll left center;
}
.title-center-con {
  background: url('../../../assets/images/h-bg-r.png') no-repeat scroll right center;
}

.info-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 16px;
}
.info-item {
  width: 326px;
  height: 120px;
  letter-spacing: 10px;
  text-align: center;
}
.type1 {
  color: #00daff;
  background: url('../../../assets/images/policy/p-3.png');
  background-size: 100% 100%;
}
.type2 {
  color: #00ffa3;
  background: url('../../../assets/images/policy/p-4.png');
  background-size: 100% 100%;
}
.info-num {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
}
.info-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  padding-top: 12px;
}

.flie-list {
  .project-apply {
    margin-bottom: 14px;
  }
}
</style>
