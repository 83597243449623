<template>
  <div>
    <div class="title-center"><div class="title-center-con">累计走访量</div></div>
    <NumberPanel :number="totalVisitNumber || 0"></NumberPanel>
    <div class="info-box">
      <div class="info-item type1">
        <div class="info-num">
          <CountTo class="count" :startVal="0" :endVal="cMonthTotalNumber || 0" :duration="1000" />
        </div>
        <div class="info-name">本月走访总量</div>
      </div>
      <div class="info-item type2">
        <div class="info-num">
          <CountTo class="count" :startVal="0" :endVal="cpyNumber || 0" :duration="1000" />
        </div>
        <div class="info-name">已走访企业</div>
      </div>
      <div class="info-item type3" style="margin-right: 0">
        <div class="info-num">
          <CountTo class="count" :startVal="0" :endVal="deptNumber || 0" :duration="1000" />
        </div>
        <div class="info-name">走访部门总数</div>
      </div>
    </div>
    <div class="map-warp">
      <nTitle name="街道预览"></nTitle>
      <div class="map-box">
        <AMap
          mapStyle="amap://styles/3c5b5047c37621e5b97d427e94ccc67f"
          :aMapKey="AMAP_KEY"
          :zoom="13"
          :showLabel="false"
          :center="[120.356409, 36.067017]"
          viewMode="3D"
          :plugins="['GeoJSON']"
          :isReady="isReady"
          :loadingText="loadingText"
          @mapLoaded="handleMapLoaded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CountTo } from 'vue-count-to2'
import nTitle from './title'
import AMap from '@/components/AMap'
import NumberPanel from './NumberPanel'
import { useLoca } from '@/hooks'
import useStreet from './useStreet'
import { getStreetAreaJson } from '@/api/map'
import { AMAP_KEY } from '@/config/commonConfig'
import { aggregateList } from '@/api/aggregate2'
import { onBeforeUnmount, ref } from 'vue'
import { CURRENT_MONTH, getEndDayOfMonth, getFirstDayOfMonth } from '@/utils/date'
export default {
  name: 'center',
  components: {
    AMap,
    nTitle,
    NumberPanel,
    CountTo
  },
  setup: () => {
    // 加载地图
    let locaObj
    const isReady = ref(false)
    const loadingText = ref(undefined)
    const partMap = {}

    const handleMapLoaded = ({ map, AMap }) => {
      const { loca } = useLoca(map)
      locaObj = window.locaObj = loca
      loadingText.value = '数据加载中...'
      Promise.all([getStreetAreaJson(), fetchData()]).then(([streetData, visitData]) => {
        streetData.features.forEach(d => {
          const name = d.properties.streetName
          const data = visitData.find(f => f.dic__id === name)
          if (data) {
            d.properties.visitCount = data.visitCount
          } else {
            d.properties.visitCount = 0
          }
        })

        streetData.visit = visitData.reduce(
          (pre, cur) => {
            return {
              min: Math.min(pre.min, cur.visitCount),
              max: Math.max(pre.max, cur.visitCount),
              total: pre.total + cur.visitCount
            }
          },
          { min: 1000, max: 0, total: 0 }
        )

        useStreet(map, AMap, loca, streetData)
        // const { layer: heatMap } = useHeatMap(loca, companyData, true)
        // const { layer: point } = useCompanyPoint(loca, companyData, false)
        // partMap.pointLayer = point
        // partMap.heatMapLayer = heatMap
        isReady.value = true
      })
    }

    // 加载页面数据
    const totalVisitNumber = ref(0)
    const cpyNumber = ref(0)
    const deptNumber = ref(0)
    const cMonthTotalNumber = ref(0)

    const fetchData = async () => {
      const startDate = getFirstDayOfMonth(CURRENT_MONTH)
      const endDate = getEndDayOfMonth(CURRENT_MONTH)
      const params = {
        'cMonth-total-visit': {
          cmdList: [
            {
              $match: {
                'data.visitTime': {
                  $gte: startDate,
                  $lte: endDate
                }
              }
            },
            { $group: { _id: '总数', visitCount: { $sum: 1 } } }
          ],
          templateName: 'b_console_visit'
        },
        'his-total-visit': {
          cmdList: [{ $group: { _id: '总数', visitCount: { $sum: 1 } } }],
          templateName: 'b_console_visit'
        },
        'his-total-cpy': {
          cmdList: [
            { $group: { _id: '$data.enterpriseId' } },
            { $group: { _id: '总数', visitCount: { $sum: 1 } } }
          ],
          templateName: 'b_console_visit'
        },
        'his-total-dept': {
          cmdList: [
            { $group: { _id: '$orgId' } },
            { $group: { _id: '总数', visitCount: { $sum: 1 } } }
          ],
          templateName: 'b_console_visit'
        },
        'map-street-visit': {
          // cmdList: [
          //   { $match: { 'data.regStreetLabel': { $nin: ['', null] } } },
          //   { $group: { _id: '$data.regStreetLabel', visitCount: { $sum: '$data.visitCount' } } },
          //   { $sort: { visitCount: -1 } }
          // ],
          cmdList: [
            { $group: { _id: '$orgId', visitCount: { $sum: 1 } } },
            { $sort: { visitCount: 1 } }
          ],
          dicCodes: [
            {
              name: '_id',
              type: 4
            }
          ],
          templateName: 'b_console_visit'
        }
      }
      const res = await aggregateList(params)
      const data = res.result
      totalVisitNumber.value = data['his-total-visit'][0]?.visitCount
      cpyNumber.value = data['his-total-cpy'][0]?.visitCount
      deptNumber.value = data['his-total-dept'][0]?.visitCount
      cMonthTotalNumber.value = data['cMonth-total-visit'][0]?.visitCount
      // console.log(cMonthTotalNumber.value)

      return data['map-street-visit']
    }
    setInterval(() => {
      fetchData()
    }, 10 * 60 * 1000)

    onBeforeUnmount(() => {
      // 页面离开时需要销毁，否则绘制有问题
      // locaObj && locaObj.animate.stop()
      locaObj && locaObj.destroy()
      Object.keys(partMap).forEach(partName => {
        const layer = partMap[partName]
        layer.unwatch && layer.unwatch()
        layer.unwatch2 && layer.unwatch2()
      })
    })

    return {
      totalVisitNumber,
      cpyNumber,
      deptNumber,
      cMonthTotalNumber,
      AMAP_KEY,
      handleMapLoaded,
      isReady,
      loadingText
    }
  }
}
</script>

<style scoped>
.title-center {
  color: rgb(93, 229, 254);
  font-family: Source Han Sans CN;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  background: url('../../../assets/images/h-bg-l.png') no-repeat scroll left center;
}
.title-center-con {
  background: url('../../../assets/images/h-bg-r.png') no-repeat scroll right center;
}

.info-box {
  overflow: hidden;
  margin-bottom: 16px;
}
.info-item {
  float: left;
  margin-right: 12px;
  width: 296px;
  height: 124px;
  text-align: center;
}
.type1 {
  color: #51abff;
  background: url('../../../assets/images/border1.png');
  background-size: 100% 100%;
}
.type2 {
  color: #fe8e3d;
  background: url('../../../assets/images/border2.png');
  background-size: 100% 100%;
}
.type3 {
  color: #57d664;
  background: url('../../../assets/images/border3.png');
  background-size: 100% 100%;
}
.info-num {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
  padding-top: 12px;
}
.info-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.map-box {
  height: 561px;
  border: 1px solid rgb(65, 67, 84);
  margin-top: 8px;
}
</style>
