/* eslint-disable no-unused-vars */
// import { useStreetName } from '@/hooks'
import { scaleLinear, scaleQuantize } from 'd3-scale'
export default (map, AMap, loca, geoJson, opts = {}) => {
  const { polygonZoom = [2, 20], iconZoom = [2, 20] } = opts
  const {
    visit: { min, max, total }
  } = geoJson

  const colorScale = scaleLinear()
    .domain([min, max])
    .range(['#5de5ff', '#019cff'])

  // 街道范围线
  const shiNanQu = new AMap.GeoJSON({
    geoJSON: geoJson,
    // 还可以自定义getMarker和getPolyline
    getPolygon: (geojson, lnglats) => {
      const {
        properties: { visitCount }
      } = geojson

      const polygon = new AMap.Polygon({
        path: lnglats,
        strokeWeight: 1.8,
        fillOpacity: 0.5,
        fillColor: colorScale(visitCount),
        strokeColor: colorScale(visitCount),
        strokeStyle: 'solid', // 轮廓线样式，实线:solid 虚线:dashed
        cursor: 'pointer'
      })
      return polygon
    },
    getMarker: (geojson, lnglats) => {
      const { streetName } = geojson.properties
      const text = new AMap.Text({
        position: lnglats,
        anchor: 'bottom-center',
        text: streetName,
        style: {
          'background-color': 'transparent',
          border: 'transparent',
          'font-size': '14px',
          'pointer-events': 'none'
        }
      })

      return text
    }
  })

  shiNanQu.setOptions({
    zooms: polygonZoom
  })

  map.add(shiNanQu)

  const textStyle = {
    fontSize: 18,
    fontWeight: 'bold',
    fillColor: '#fff',
    strokeColor: '#fff',
    strokeWidth: 1
    // fold: true
    // padding: '5, 10',
    // backgroundColor: '#0091ff',
    // borderColor: '#161e31',
    // borderWidth: 3
  }

  const labelsLayer = new AMap.LabelsLayer({
    map,
    zooms: iconZoom,
    zIndex: 1000,
    // 该层内标注是否避让
    collision: false,
    // 设置 allowCollision：true，可以让标注避让用户的标注
    allowCollision: false,
    visible: true
  })

  const centers = geoJson.features.filter(d => d.geometry.type === 'Point')

  centers.forEach(d => {
    const labelMarker = new AMap.LabelMarker({
      position: d.geometry.coordinates,
      text: {
        content: String(d.properties.visitCount),
        direction: 'center',
        style: textStyle,
        offset: [0, 10]
      }
    })
    labelsLayer.add(labelMarker)
  })

  map.add(labelsLayer)

  const show = () => {
    labelsLayer.show(1000)
    shiNanQu.show(1000)
  }

  const hide = () => {
    labelsLayer.hide(1000)
    shiNanQu.hide(1000)
  }

  const animateConfit = [
    {
      // 地图中心点动画
      center: {
        // value: [120.26897, 36.353962], // 动画终点的经纬度
        value: [120.359331, 36.065003], // 动画终点的经纬度
        control: [
          [120.359331, 36.065003],
          [120.359331, 36.065003]
        ], // 过渡中的轨迹控制点，地图上的经纬度
        timing: [0, 0, 0, 1], // 动画时间控制点
        duration: 2000 // 过渡时间，毫秒（ms）
      },
      pitch: {
        value: 0, // 动画终点的俯仰角度
        control: [
          [0.3, 0],
          [0.4, 0]
        ], // 控制器，x是0～1的起始区间，y是pitch值
        timing: [0, 0, 1, 1], // 这个值是线性过渡
        duration: 2000
      },
      rotation: {
        value: 0, // 动画终点的地图旋转角度
        control: [
          [0, 0],
          [1, 0]
        ], // 控制器，x是0～1的起始区间，y是rotation值
        timing: [0, 0, 1, 1],
        duration: 2000
      },
      // 缩放等级动画
      zoom: {
        value: 13.5, // 动画终点的地图缩放等级
        control: [
          [0, 12],
          [1, 13]
        ], // 控制器，x是0～1的起始区间，y是zoom值
        timing: [0, 0, 1, 1],
        duration: 2000
      }
    }
  ]

  const animate = () => {
    loca.viewControl.addAnimates(animateConfit, () => {
      show()
    })
    loca.animate.start()
  }

  return { show, hide, animate }
}
