<template>
  <div class="n-left-box">
    <boxItem style="margin-bottom: 12px" title="历史趋势" contentHeight="256">
      <VEchart :option="historyOption" />
    </boxItem>
    <boxItem style="margin-bottom: 12px" title="企业分类" contentHeight="256">
      <VEchart :option="companyOption" />
    </boxItem>
    <boxItem title="部门走访" contentHeight="256">
      <webkitScroll>
        <div style="height: 800px">
          <VEchart :option="visitOption" />
        </div>
      </webkitScroll>
    </boxItem>
  </div>
</template>

<script>
import boxItem from './boxItem'
import webkitScroll from './webkitScroll'
import { aggregateList } from '@/api/aggregate2'
import { ref } from 'vue'
import * as echarts from 'echarts'
export default {
  name: 'left',
  components: {
    boxItem,
    webkitScroll
  },
  setup: () => {
    // 历史趋势
    const historyOption = ref({})
    function history(data) {
      historyOption.value = {
        grid: {
          top: 30,
          bottom: 30
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#D6FDFF'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#D6FDFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: '走访次数',
            data: data.seriesData,
            type: 'line',
            symbol: 'none',
            smooth: true,
            itemStyle: {
              color: '#019CFF'
            },
            lineStyle: {
              width: 2
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(1, 156, 255, 0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(33, 35, 46, 0)'
                }
              ])
            }
          }
        ]
      }
    }

    // 企业分类
    const companyOption = ref({})
    function company(data) {
      companyOption.value = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        // color: ['#51ABFF', '#FE8E3D', '#57D664', '#EF7AE3'],
        legend: {
          show: false
        },
        series: [
          {
            name: '企业分类',
            type: 'pie',
            radius: '70%',
            label: {
              formatter: '{b|{b}}\n{per|{d}%} ',
              rich: {
                b: {
                  color: '#fff',
                  lineHeight: 22,
                  fontSize: 14,
                  align: 'left'
                },
                per: {
                  color: '#fff',
                  lineHeight: 22,
                  fontSize: 12,
                  align: 'left'
                }
              }
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }

    // 部门走访
    const visitOption = ref({})
    function visit(data) {
      visitOption.value = {
        grid: {
          left: 120,
          top: 10,
          bottom: 30
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          },
          data: data.xData
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            color: '#D6FDFF'
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: ['rgba(204, 253, 255, 0.1)']
            }
          }
        },
        series: [
          {
            name: '走访数',
            data: data.seriesData,
            type: 'bar',
            showBackground: false,
            barMaxWidth: 8,
            label: {
              show: false
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: 'rgb(1, 156, 255)'
                },
                {
                  offset: 1,
                  color: 'rgb(93, 229, 254)'
                }
              ])
            }
          },
          {
            data: data.seriesBigData,
            type: 'bar',
            barMaxWidth: 8,
            barGap: '-100%',
            tooltip: {
              show: false
            },
            showBackground: false,
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              formatter: function(params) {
                return data.seriesData[params.dataIndex]
              }
            },
            itemStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
    }

    const fetchData = async () => {
      const params = {
        'his-visit': {
          cmdList: [
            {
              $project: {
                visitDateMonth: { $substr: ['$data.visitTime', 0, 7] }
              }
            },
            { $group: { _id: '$visitDateMonth', visitCount: { $sum: 1 } } },
            { $sort: { _id: 1 } }
          ],
          templateName: 'b_console_visit'
        },
        'his-cpytype-visit': {
          cmdList: [
            { $unwind: { path: '$data.cpyTypes', preserveNullAndEmptyArrays: true } },
            {
              $project: {
                typeNull: {
                  $or: [
                    { $eq: [{ $type: '$data.cpyTypes' }, 'missing'] },
                    { $in: ['$data.cpyTypes', ['', '其他', '其它', null]] }
                  ]
                },
                type: '$data.cpyTypes'
              }
            },
            { $group: { _id: { $cond: ['$typeNull', '其它', '$type'] }, count: { $sum: 1 } } },
            { $sort: { _id: -1 } }
          ],
          templateName: 'b_console_visit'
        },
        'his-dep-visit': {
          cmdList: [
            { $group: { _id: '$orgId', visitCount: { $sum: 1 } } },
            { $sort: { visitCount: 1 } }
          ],
          dicCodes: [
            {
              name: '_id',
              type: 4
            }
          ],
          templateName: 'b_console_visit'
        }
      }
      const res = await aggregateList(params)
      // console.log(res)
      // 历史趋势
      const historyData = {
        xData: [],
        seriesData: []
      }
      res.result['his-visit'].forEach(d => {
        historyData.xData.push(d.id)
        historyData.seriesData.push(d.visitCount || 0)
      })
      history(historyData)

      // 企业分类
      const companyData = []
      res.result['his-cpytype-visit'].forEach(item => {
        companyData.push({
          value: item.count,
          name: item.id
        })
      })
      company(companyData)

      // 部门走访
      const visitData = {
        xData: [],
        seriesData: [],
        seriesBigData: []
      }
      let max = 0
      console.log(
        '%c [ res.result["his-dep-visit"] ]-324',
        'font-size:13px; background:pink; color:#bf2c9f;',
        res.result['his-dep-visit']
      )
      res.result['his-dep-visit'].forEach(item => {
        visitData.xData.push(item.dic__id)
        visitData.seriesData.push(item.visitCount)
      })
      max = Math.max.apply(null, visitData.seriesData) * 1.1
      visitData.seriesData.forEach(() => {
        visitData.seriesBigData.push(max < 10 ? 10 : max)
      })
      visit(visitData)
      // console.log(res)
    }
    fetchData()
    setInterval(() => {
      fetchData()
    }, 10 * 60 * 1000)
    return { historyOption, companyOption, visitOption }
  }
}
</script>

<style scoped>
.n-left-box {
}
</style>
